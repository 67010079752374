<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="账单类型">
              <a-select
                v-model="queryParam.type"
                placeholder="请选择账单类型"
                @change="queryList"
              >
                <a-select-option v-for="op in typeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model.trim="queryParam.serial_num" placeholder="根据订单编号搜索" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item :label="queryParam.type === 0 ? '委托客户' : '购买单位'">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="是否开票">
              <a-select
                v-model="queryParam.whether_invoice"
                placeholder="请选择是否开票"
                allowClear
                @change="queryList"
              >
                <a-select-option v-for="op in whetherInvoiceData" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="queryList">查询</a-button>
      <a-button type="primary" icon="download" @click="exportAllProfitSheet">导出</a-button>
      <a-popover
        v-model="popVisible"
        title="选择月份"
        trigger="click">
        <a slot="content">
          <p><a-month-picker v-model="exportDate" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
          <p style="text-align: right"><a-button type="primary" @click="exportProfitSheet">确定</a-button></p>
        </a>
        <a-button type="primary">利润明细表</a-button>
      </a-popover>
    </div>

    <s-table
      ref="table"
      size="small"
      :rowKey="(record) => { return record.sell_num || record.agent_num }"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal
      }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="order_num_title">
        {{ queryParam.type === 2 ? '代销订单编号' : '出货订单编号' }}
      </span>
      <span slot="order_num" slot-scope="text, record">
        {{ record.purchase_num || record.sell_num || record.agent_num }}
      </span>
      <span slot="sale_name_title">
        {{ queryParam.type === 0 ? '委托客户' : '购买单位' }}
      </span>
      <span slot="sale_name" slot-scope="text, record">
        {{ record.supplier_name || record.buyer_name }}
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { downloadExcel, getCustomer } from '@/api/common'
import { getWmsSaleFinancePage, exportWmsSaleFinance, wmsSaleProfitTotal } from '@/api/wms'
import { exportProfitSheet, exportOrderProfitSheet } from '@/api/wms/sale'
import debounce from 'lodash/debounce'

export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)

    return {
      queryParam: {
        type: 1
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          slots: { title: 'order_num_title' },
          scopedSlots: { customRender: 'order_num' },
          width: 150
        },
        {
          slots: { title: 'sale_name_title' },
          scopedSlots: { customRender: 'sale_name' },
          width: 200
        },
        {
          title: '出货总价',
          dataIndex: 'price_out',
          width: 100
        },
        {
          title: '进货总价',
          dataIndex: 'price_in',
          width: 100
        },
        {
          title: '快递费',
          dataIndex: 'courier_fee',
          width: 100
        },
        {
          title: '利润',
          dataIndex: 'profit',
          width: 100
        },
        {
          title: '去税利润',
          dataIndex: 'tax_profit',
          width: 100
        },
        {
          title: '销售利润',
          dataIndex: 'sale_profit',
          width: 100
        }
      ],
      loadData: (parameter) => {
        return getWmsSaleFinancePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      popVisible: false,
      exportDate: null,
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      showTotal: '利润合计: 0；去税利润合计: 0',
      typeOps: [
        { value: 1, name: '出货' },
        { value: 2, name: '代销' }
      ],
      whetherInvoiceData: [
        { value: 0, name: '未开票' },
        { value: 1, name: '已开票' }
      ]
    }
  },
  mounted() {
    this.initTotal()
  },
  methods: {
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    onInvoiceDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_start_date'] = startDate
        this.queryParam['invoice_end_date'] = endDate
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    queryList() {
      this.$refs.table.refresh(true)
      this.initTotal()
    },
    initTotal() {
      wmsSaleProfitTotal(this.queryParam).then(res => {
        if (this.queryParam.type === 2) {
          this.showTotal = `出货总价: ${res.price_out.toFixed(2)}；进货总价: ${res.price_in.toFixed(2)}；
          利润合计: ${res.total_profit.toFixed(2)}；去税利润合计: ${res.total_tax_profit.toFixed(2)}；销售利润合计: ${res.total_sale_profit.toFixed(2)}`
        } else {
          this.showTotal = `出货总价: ${res.price_out.toFixed(2)}；进货总价: ${res.price_in.toFixed(2)}；利润合计: ${res.total_profit.toFixed(2)}；去税利润合计: ${res.total_tax_profit.toFixed(2)}`
        }
        
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 8,
        customerName: value,
        type: 'all'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value !== undefined) {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
      } else {
        this.customerInfo = undefined
        this.queryParam['customer_id'] = null
      }
      this.customerOps = []
      this.fetching = false
    },
    handleExport() {
      exportWmsSaleFinance('export_profit_data', this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    exportAllProfitSheet () {
      exportOrderProfitSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        })
    },
    // 导出利润明细表
    exportProfitSheet() {
      if (this.exportDate) {
        const param = {
          year: this.exportDate.year(),
          month: this.exportDate.month() + 1
        }
        exportProfitSheet(param).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    }
  }
}
</script>
